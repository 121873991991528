import {get, getPublic, post, postPublic, postPublicUrlEncoded, addToUploadList, uploadMultipartFile} from "./api";
import deviceInfoService from "../services/DeviceInfoService"
import {suggestIdentifiers, FILE_CHUNK_SIZE, MAX_FILE_SIZE_WITHOUT_MULTIPART} from "./fileUtils";
import configService from "../services/ConfigService"

async function getDeviceId() {
    const visitorData = await deviceInfoService.getVisitorData();
    return visitorData.visitorId;
}

export async function sendFeedback(name, email, message, token) {
    const {apiHost} = await configService.getConfig();
    return postPublicUrlEncoded(`${apiHost}/api/v1/pub/feedbacks`, {
        name,
        email,
        message,
        token,
        channel: 'Web',
        'g-recaptcha-response': token
    });
}

export async function sendCodeToPhone(phone, country, channel) {
    const {apiHost} = await configService.getConfig();
    return postPublic(`${apiHost}/api/v1/pub/signup/web/code/send?channel=${channel}`, {phone, deviceId: await getDeviceId()});
}

export async function verifyPhone(phone, code) {
    const {apiHost} = await configService.getConfig();
    return postPublic(`${apiHost}/api/v1/pub/signup/code/verify`, {phone, deviceId: await getDeviceId(), code});
}

export async function confirmPhone(phone, code) {
    const {apiHost} = await configService.getConfig();
    const visitorData = await deviceInfoService.getVisitorData();
    const deviceInfo = {
        type: "Web",
        model: "browser",
        appVersion: "1.0.0",
        vendor: visitorData.components.vendor != null ? visitorData.components.vendor.value || "unknown" : "unknown",
        osVersion: visitorData.components.platform != null ? visitorData.components.platform.value || "unknown" : "unknown",
        locale: navigator.language || navigator.userLanguage || "en"
    }
    return postPublic(`${apiHost}/api/v1/pub/signup/code/confirm`, {
        phone,
        deviceId: visitorData.visitorId,
        code,
        deviceInfo
    });
}

export async function refreshToken(refreshToken) {
    const {apiHost} = await configService.getConfig();
    return getPublic(`${apiHost}/api/v1/pub/signup/token/${refreshToken}`);
}

export async function createImportJob(quality, items) {
    const {apiHost} = await configService.getConfig();
    const files = items.map((item, index) => {
        const identifiers = suggestIdentifiers(item.file);
        return {
            index: identifiers.suggestedIndex,
            filename: item.file.webkitRelativePath || item.filename,
            size: item.file.size,
            lastModifiedDate: item.file.lastModified,
            suggestedExtId: identifiers.suggestedExtId,
            partsCount: item.file.size > MAX_FILE_SIZE_WITHOUT_MULTIPART ? (item.file.size / FILE_CHUNK_SIZE) + 1 : null
        }
    });

    return post(`${apiHost}/api/v1/imports`, {
        splitByYears: false,
        quality,
        files
    });
}

export async function getJobState(id) {
    const {apiHost} = await configService.getConfig();
    return get(`${apiHost}/api/v1/imports/${id}`);
}

export async function getJobStatus(id, withItems) {
    const {apiHost} = await configService.getConfig();
    return get(`${apiHost}/api/v1/imports/${id}/status?withItems=${withItems}`);
}

export async function startJob(id) {
    console.log(`StartJob(${id}) - skipped (obsoleted)`)
    //const {apiHost} = await configService.getConfig();
    //return post(`${apiHost}/api/v1/imports/${id}/start`);
    return new Promise(() => {})
}

export async function cancelJob(id) {
    const {apiHost} = await configService.getConfig();
    return post(`${apiHost}/api/v1/imports/${id}/cancel`);
}

export async function cancelMediaItem(importJobId, item) {
    const {apiHost} = await configService.getConfig();
    return post(`${apiHost}/api/v1/imports/${importJobId}/medias/${item.mediaId}/cancel`, {}, false);
}

export async function getInviteChat(userTag) {
    const {apiHost} = await configService.getConfig();
    return await getPublic(`${apiHost}/api/v1/pub/invite/chat/${userTag}`);
}

export async function getInviteGroup(groupId, token) {
    const {apiHost} = await configService.getConfig();
    return getPublic(`${apiHost}/api/v1/pub/invite/group/${groupId}${token ? `?token=${token}` : ''}`);
}

export async function getShared(pageId, search) {
    const {apiHost} = await configService.getConfig();
    return getPublic(`${apiHost}/api/v1/pub/shared/${pageId}${search}`);
}

export async function getWebShared(pageId, search) {
    const {apiHost} = await configService.getConfig();
    return getPublic(`${apiHost}/api/v1/pub/webshared/${pageId}${search}`);
}

export async function uploadFilesChunk(items, percentChangeCallback){
    // https://www.useanvil.com/blog/engineering/throttling-and-consuming-apis-with-429-rate-limits/
    // https://www.developerway.com/posts/how-to-fetch-data-in-react
    const chunkSize = 20;
    for (let i = 0; i < items.length; i += chunkSize) {
        const chunk = items.slice(i, i + chunkSize);
        await uploadFiles(chunk, percentChangeCallback)
    }
}

export async function completeMultipartUpload(parts) {
    console.log('COMPLETE MULTI');
    const {apiHost} = await configService.getConfig();
    return post(`${apiHost}/api/v1/imports/${parts.importId}/medias/${parts.mediaId}/multipart/complete`, {
        uploadId: parts.uploadId,
        uploadedParts: parts.parts
    });
}

export async function completeUpload(item) {
    const {apiHost} = await configService.getConfig();
    return post(`${apiHost}/api/v1/imports/${item.importId}/medias/${item.mediaId}/upload/complete`, {});
}

export async function abortMultipartUpload(parts) {
    const {apiHost} = await configService.getConfig();
    return post(`${apiHost}/api/v1/imports/${parts.importId}/medias/${parts.mediaId}/abort?uploadId=${parts.uploadId}`, {});
}

export function uploadFiles(items, percentChangeCallback) {
    return Promise.all(items.map(async item => {
        if(item.uploadId){
            console.log(`START MULTIPART UPLOAD for media - ${item.mediaId}`)
            await uploadMultipartFile(item, (event) => {
                if (event.lengthComputable) {
                    const percent = event.loaded / event.total * 100;
                    item.uploadingPercent = Math.ceil(percent);
                    percentChangeCallback(item);
                }
            });
        } else {
            console.log(`START REGULAR UPLOAD for media - ${item.mediaId}`)
            addToUploadList(item.writableUrl, item.file, 5, (event) => {
                if (event.lengthComputable) {
                    const percent = event.loaded / event.total * 100;
                    item.uploadingPercent = Math.ceil(percent);
                    percentChangeCallback(item);
                }
            }, async (response) => {
                console.log(`Uploaded media ${item.mediaId}`);
                await completeUpload(item);
                return true;
            }, true);
        }
    }));
}


// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
    return new Promise((resolve) =>
        setTimeout(() => resolve({data: amount}), 500)
    );
}
