import React from 'react';
import css from './FramerFeedbackPage.module.css';
import {sendFeedback} from '../utils/coreAPI';
import ReCAPTCHA from "react-google-recaptcha";
import {useLocation} from "react-router-dom";

class FramerFeedbackPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contactUsButtonDisabled: true,
            name: '',
            email: '',
            emailValid: true,
            message: '',
            recaptchaRef: React.createRef(),
            recaptchaValue: null,
            errorSendingFeedback: null,
            successSendingFeedback: false
        };
    }

    handleNameChange(event) {
        const {email, message, recaptchaValue} = this.state;
        this.setState({name: event.target.value});
        this.validateContactUsForm(event.target.value, email, message, recaptchaValue);
    }

    handleEmailChange(event) {
        const {name, message, recaptchaValue} = this.state;
        const email = event.target.value;
        const emailValid = this.validateEmail(email);
        this.setState({email, emailValid});
        this.validateContactUsForm(name, email, message, recaptchaValue);
    }

    handleMessageChange(event) {
        const {name, email, recaptchaValue} = this.state;
        this.setState({message: event.target.value});
        this.validateContactUsForm(name, email, event.target.value, recaptchaValue);
    }

    validateContactUsForm(name, email, message, recaptchaValue) {
        if (name !== ''
            && message !== ''
            && recaptchaValue != null) {
            this.setState({contactUsButtonDisabled: false, errorSendingFeedback: null});
        } else {
            this.setState({contactUsButtonDisabled: true, errorSendingFeedback: null});
        }
    }

    // https://denis-creative.com/validatsiya-email-s-pomoshhyu-javascript/
    validateEmail(email) {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return email === '' || re.test(String(email).toLowerCase());
    }

    async handleContactUsClick() {
        // nothing to do in case if contact button are disabled
        if (this.state.contactUsButtonDisabled) {
            return;
        }

        const {name, email, message, recaptchaValue} = this.state;
        const emailIsValid = this.validateEmail(email)

        if (!emailIsValid) {
            this.setState({errorSendingFeedback: 'Invalid e-mail'})
            return;
        }

        try {
            const response = await sendFeedback(name, email, message, recaptchaValue);

            if (response.ok) {
                this.setState({successSendingFeedback: true})
            } else {
                this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
            }
        } catch (e) {
            this.setState({errorSendingFeedback: 'Error sending feedback. Please try later'})
        }
    }

    handleReCaptcha(event) {
        const {name, email, message} = this.state;
        this.setState({recaptchaValue: event});
        this.validateContactUsForm(name, email, message, event);
    }

    render() {
        return (

            <main>

                <div className={css.page}>
                    <div className={css.wrap}>
                        <div className={css.section}>
                            <div className={css.section__wrap}>

                                <div style={this.state.successSendingFeedback ? {display: 'none'} : {}}>

                                    <div className={css.modal__warning}
                                         style={{display: this.state.errorSendingFeedback != null ? 'block' : 'none'}}>
                                        <div className={css.modal__warning_text}>{this.state.errorSendingFeedback}</div>
                                    </div>
                                    <form className={css.form} onSubmit={e => e.preventDefault()}>
                                        <input className={`${css.input} ${css.input_text}`} type={"text"}
                                               placeholder={'Name'} value={this.state.name} required={true}
                                               onChange={this.handleNameChange.bind(this)}/>
                                        <span>
                                        <input
                                            className={`${css.input} ${css.input_text} ${this.state.emailValid ? '' : css.input_error}`}
                                            type={"email"} placeholder={'E-mail*'} value={this.state.email}
                                            onChange={this.handleEmailChange.bind(this)}/>
                                        </span>
                                        <textarea className={css.textarea} placeholder={'Message'}
                                                  value={this.state.message} required={true}
                                                  onChange={this.handleMessageChange.bind(this)}></textarea>
                                        <label className={css.form__captcha}>
                                            <ReCAPTCHA
                                                ref={this.state.recaptchaRef}
                                                theme={'light'}
                                                onChange={this.handleReCaptcha.bind(this)}
                                                sitekey={'6LeZOeYUAAAAANXOo0sWCX8AoP8uaW063zrd332V'}/>
                                        </label>
                                        <div className={css.form__button}>
                                            <button className={`${css.button}`}
                                                    onClick={this.handleContactUsClick.bind(this)}
                                                    data-disabled={this.state.contactUsButtonDisabled}>
                                                <span className={`${css.button__text}`}>Send</span>
                                            </button>
                                        </div>


                                        <div className={css.terms}>
                                            By clicking <span className={css.terms_send}>"Send"</span>, you agree to and accept our <br/>
                                            <a href={"/terms.html"} target='_blank' rel="noopener noreferrer">Terms of
                                                Service</a>
                                            <span> and </span>
                                            <a href={"/privacy.html"} target='_blank' rel="noopener noreferrer">Privacy
                                                Policy</a>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className={css.success__section}
                                 style={!this.state.successSendingFeedback ? {display: 'none'} : {}}>
                                <div>
                                    <div className={css.success__title}>
                                        Thank you!
                                    </div>
                                    <div className={css.success__text}>
                                        Your request has been successfully delivered, and we will contact you soon.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

function withParams(Component) {
    return props => <Component {...props} location={useLocation()}/>;
}

export default withParams(FramerFeedbackPage);